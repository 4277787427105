
class Callbacks {
  static getTotalHandler(handling, base, driver_package_fee, fees, prepaid, duration_amount, option_amount) {
    return function getTotal() {
      const total = _.compact([
        _.toNumber(handling),
        _.toNumber(base),
        _.toNumber(driver_package_fee),
        _.toNumber(fees),
        _.toNumber(prepaid),
        _.toNumber(duration_amount),
        _.toNumber(option_amount)
      ])
      return _.round(_.sum(total), 2).toFixed(2)
    }
  }
}

export default Callbacks
