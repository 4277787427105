import React from 'react'
import { I18n } from '@front/volcanion'

import { MapFactory, ModelAutocompleteField } from '@front/squirtle'
import { Button, Grid, Typography, Box } from '@mui/material'
import UserUtils from '@front/volcanion/utils/user'
import MapComponent from './Map'
import { Call } from '@mui/icons-material'
import DispatchErrors from '@abra/elements/Dispatch/Errors'
import DriverComponent from '@abra/elements/Dispatch/Driver'

const Row1 = props =>
  <Grid item xs={12}>
    <Typography sx={{ fontSize: 'h6.fontSize', color: 'primary.main', textAlign: 'center', fontWeight: 300 }}>
      {I18n.t('map.manual_dispatch.long')}
    </Typography>
  </Grid>

const Row2 = props =>
  <Grid container item xs={12} justifyContent='center'>
    <Grid item xs={8}>
      <ModelAutocompleteField
        name={'user_id'}
        label={I18n.t('map.manual_dispatch.short')}
        model_name={'user'}
        searchMode='search'
        searchQuery={'='}
        autocompleteMutator={q => ({
          or: [
            { info: { user_client_id: { startsWith: _.split(q, ' ') } } },
            { info: { first_name: { startsWith: _.split(q, ' ') } } },
            { info: { last_name: { startsWith: _.split(q, ' ') } } }]
        })}
        labelKeys={['label']}
        multiple={false}
        loadOnFocus={false}
        autoFocus
        config={{
          forced_filter: { driverinfo: { '!': null }, status: [{ name: 'active' }] },
          populate: ['info', 'customerinfo'],
          mutator: user => ({
            label: UserUtils.getFormatedName(
              { ...user?.info, designation: user?.customerinfo?.name },
              { prefix: user?.info?.user_client_id }
            ),
            ...user
          })
        }}
        selectSingleOption
        debounceTime={400}
      />
    </Grid>
  </Grid>

const Row3 = (props) =>
  <Grid item xs={12} >
    <DriverComponent />
  </Grid>


const Row4 = ({ order_id, driver_id, }) =>
  <Grid item xs={12}>
    <DispatchErrors order_id={order_id} driver_id={driver_id} />
  </Grid>

const Row5 = ({ canCallDriver, callDriver }) =>
  <Grid item xs={12} hidden={!canCallDriver}>
    <Button startIcon={<Call />} onClick={callDriver}>
      {I18n.t('call.driver')}
    </Button>
  </Grid>

const ManualDispatch = ({ order_id, user_id, canCallDriver, callDriver }) => <>
  <Grid container spacing={1} alignItems='center'>
    <Grid item xs={12}>
      <MapComponent order_id={order_id} >
        <MapFactory.LayersControl>
          <MapFactory.ControlBox defaultOpen position={'topright'}>
            <Box sx={{ width: 400 }}>
              <Grid container rowSpacing={2} alignItems='center'>
                <Row1 />
                <Row2 />
                <Row3 />
                <Row4 order_id={order_id} driver_id={user_id} />
                <Row5 canCallDriver={canCallDriver} callDriver={callDriver} />
              </Grid >
            </Box>
          </MapFactory.ControlBox>
        </MapFactory.LayersControl>
      </MapComponent>
    </Grid>
  </Grid>
</>
export default React.memo(ManualDispatch)
