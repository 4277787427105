import React, { useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'
import FrameCallbacks from '../../callbacks'

const withContainer = Component => props => {
  const { openNotification } = hooks.useNotification()
  const identity = hooks.useIdentity()
  const billing_status = hooks.useBillingStatusList()
  const [{ record_id, isDurationRideMode, displayDownloadBillingButton }] = hooks.useFormState()
  const [
    load_type,
    handling,
    base,
    driver_package_fee,
    prepaid,
    fees,
    duration_amount,
    option_amount
  ] = hooks.useFieldValues(['load_type', 'handling', 'base', 'driver_package_fee', 'prepaid', 'fees', 'duration_amount', 'option_amount', 'billing_status'])

  const [order] = hooks.useModel('order', [record_id], { single: true, populate: ['specific_parameter', 'quotation'] })
  const [downloadInvoice] = hooks.useOrderInvoice(record_id)
  const handleInvoiceDownload = useCallback(FrameCallbacks.handleInvoiceDownloadHanlder(downloadInvoice, openNotification), [downloadInvoice, openNotification])

  const included_waiting_minute = _.get(order, 'specific_parameter.included_waiting_minute') || 0

  const total = useMemo(Callbacks.getTotalHandler(handling, base, driver_package_fee, fees, prepaid, duration_amount, option_amount), [handling, base, driver_package_fee, fees, prepaid, duration_amount, option_amount])

  const displayDriverPackageFee = identity !== 'HP'

  const mergedProps = {
    billing_status,
    total,
    included_waiting_minute,
    load_type,
    isDurationRideMode,
    displayDriverPackageFee,
    displayDownloadBillingButton,
    handleInvoiceDownload
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
